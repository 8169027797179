// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aiaprivacy-index-cn-js": () => import("./../../../src/pages/aiaprivacy/index.cn.js" /* webpackChunkName: "component---src-pages-aiaprivacy-index-cn-js" */),
  "component---src-pages-cprivacy-index-cn-js": () => import("./../../../src/pages/cprivacy/index.cn.js" /* webpackChunkName: "component---src-pages-cprivacy-index-cn-js" */),
  "component---src-pages-cterms-index-cn-js": () => import("./../../../src/pages/cterms/index.cn.js" /* webpackChunkName: "component---src-pages-cterms-index-cn-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-index-cn-js": () => import("./../../../src/pages/privacy/index.cn.js" /* webpackChunkName: "component---src-pages-privacy-index-cn-js" */),
  "component---src-pages-privacy-index-en-js": () => import("./../../../src/pages/privacy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-index-en-js" */),
  "component---src-pages-r-index-js": () => import("./../../../src/pages/r/index.js" /* webpackChunkName: "component---src-pages-r-index-js" */),
  "component---src-pages-terms-index-cn-js": () => import("./../../../src/pages/terms/index.cn.js" /* webpackChunkName: "component---src-pages-terms-index-cn-js" */),
  "component---src-pages-terms-index-en-js": () => import("./../../../src/pages/terms/index.en.js" /* webpackChunkName: "component---src-pages-terms-index-en-js" */)
}

