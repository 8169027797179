import { getUserInfo, getGuideStatus, getJumpProto } from './api'
export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : ''

const setUser = user =>
  window.localStorage.setItem("user", JSON.stringify(user))

export const handleLogin = async () => {
  try {
    const user = await getUserInfo()
    setUser(user)
    return user
  } catch (error) {

  }
}

export const isLoggedIn = () => {
  const user = getUser()
  return user && Object.keys(user).length > 0 
}

export const getJumpProtoIme = async() => {
  try {
    await getJumpProto()
  } catch (error) {
    console.log(error)
  }
}

export const getUserStatus = async () => {
  try {
    return await getGuideStatus()
  } catch (error) {

  }
}