require('./src/styles/global.css')
const {param2Obj,isPhone} = require('./utils')
const {setLang,i18nLanguage}  = require('./utils/lang')
const {gotinTrack} = require('@frontend/gotin-track')

exports.onClientEntry = () => {
  console.log(window.location.href,window, "gotin entry")
  const alias = window.location.pathname.indexOf('r') !== -1 ?  window.location.pathname.split('/')[2] : ''
  if (alias) {
    localStorage.setItem('alias', alias)
  }
  const { token, refresh_token,expired_time, lang  } = param2Obj(window && window.location.href)
  if (token) {
    localStorage.setItem('Authorization', token)
    localStorage.setItem('refresh_token', refresh_token)
    localStorage.setItem('expired_time', expired_time)
    // window.location.search = ''
    // window.location.replace(window.location.origin)
    window.history.pushState('', null, window.location.origin);
  }
  if (lang) {
    localStorage.setItem(i18nLanguage, lang)
  }
  window.gotinTrack = gotinTrack;

  // location.reload()
  console.log("new pathname", window.location)
}
