import { isBrowser } from "./../src/service/auth";

const i18nLanguage = "i18nLanguage";

function defaultLocale() {
  //判断浏览器的首选语言
  if (isBrowser() && window.navigator.language.indexOf("zh") >= 0) {
    return "cn";
  }
  return "en";
}

// 语言选择
function setLang(value) {
  if (!value) {
    value = defaultLocale()
  }
  isBrowser() && window.localStorage.setItem(i18nLanguage, value);
}

function getLang() {
  return isBrowser() && window.localStorage.getItem(i18nLanguage) || defaultLocale();
}

export {i18nLanguage, setLang, getLang};
