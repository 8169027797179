import request from './index'
export const getUserInfo = () => {
  return request.get('/web/user/info')
}

export const getJumpProto = () => {
  return request.get("/web/user/regional_check")
}

// 获取用户是否完成引导步骤
export const getGuideStatus = () => {
  return request.get(`${process.env.GATSBY_CREATOR_URL}/guide/status`)
}