import axios from "axios";
import {Scheme} from '../enum/scheme'
import {loginGotin} from '../../utils/login'
import loginSDK from "../../utils/base";

const instance = axios.create({
  baseURL: process.env.GATSBY_USER_URL,
});

instance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      config.headers.Authorization = localStorage.getItem("Authorization");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 手机号正则
const phoneReg = /^1[3456789]\d{9}$/;
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    const {code, data} = res

    if (code === 0) {
      return data
    } 
    if (code === 10) {
      return null
    }
    // 补充个人信息但不立即登录
    // 需要如何设计？才能更解耦
    if (code === 50  && data.scheme !== Scheme.NeedLogin) {
      loginSDK.handleScheme(data.scheme, loginGotin)
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance