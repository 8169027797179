import {v4 as uuidv4} from 'uuid'
import Day from 'dayjs'

function createHttpRequest () {
  return new XMLHttpRequest()
}
function AliLogTracker (host, project, logstore, config) {
  window.initedTrackers = {}
  var trackerKey = config['__topic__'] + config['__source__']
  if (trackerKey in window.initedTrackers) {
    return window.initedTrackers[trackerKey];
  }
  this.uri_ = 'https://' + project + '.' + host + '/logstores/' + logstore + '/track?APIVersion=0.6.0';
  this.params_ = new Array();
  this.config = config
  this.loggerList = []
  this.flushState = null
  window.initedTrackers[trackerKey] = this
}

AliLogTracker.prototype = {
  push: function (key, value) {
    if (!key || !value) {
      return;
    }
    this.params_.push(new String(key));
    this.params_.push(new String(value));
  },
  logger: function () {
    let log = {}
    while (this.params_.length > 0) {
      let logKey = this.params_.shift()
      let logValue = this.params_.shift()
      log[logKey] = logValue
    }

    this.loggerList.push(log)
    this.params_ = new Array()
  },
  flush: function () {
    if (this.loggerList.length == 0) {
      return;
    }

    if (this.flushState) {
      return;
    }

    var timeOutFuture = null;
    try {
      this.flushState = 'flushing'

      let bodyStr = JSON.stringify({
        ...this.config,
        __logs__: this.loggerList
      })

      if (bodyStr.length > 5000) {
        console.log('长度超出', bodyStr.length)
        this.loggerList = []
        return
      }

      var httpRequest_ = createHttpRequest()
      timeOutFuture = setTimeout(() => {
        if (httpRequest_ && (httpRequest_.readyState < 4)) {
          httpRequest_.abort();
        }
      }, 3000)
      
      this.loggerList = []
      httpRequest_.open("POST", this.uri_, false)
      httpRequest_.setRequestHeader("x-log-apiversion", "0.6.0")
      httpRequest_.setRequestHeader("x-log-bodyrawsize", new String(bodyStr.length))
      httpRequest_.send(bodyStr);
      // this.loggerList = []
    }
    catch (ex) {
      if (window && window.console && typeof window.console.log === 'function') {
        console.log("Failed to log to ali log service because of this exception:\n" + ex);
      }
    } finally {
      this.flushState = null
      if (timeOutFuture) {
        clearTimeout(timeOutFuture)
      }
    }
  }
};

function tracking (param = {}) {
  // data_type: 三种 PV CLICK pageshare
  // __source__: 对应data_type三种值 pv-data click-data page-share-data
  /**
   * click事件时需要传的参数
   * data_type: 不为PV时
   * session_id: sessionId 路由没有sessionId但是需要sessionId时
   * data为动态传递的数据，字段名不定，具体传参可根据数据端要求
   * data内的某些参数含义：type-事件名(如标签筛选) labels_value-标签名  node_id-物料id  target_url-外链 element-点击的元素dom
   */
  // 如有需要获取的固定字段，dom元素可添加name属性，数据层获取name值记录
  let params = { ...param }
  const source = {
    pv: 'pv-data',
    click: 'click-data',
    pageshare: 'page-share-data'
  }
  var Trackering = new AliLogTracker(
    process.env.GATSBY_LOGHUB_HOST,
    process.env.GATSBY_LOGHUB_PROJECT,
    process.env.GATSBY_LOGHUB_LOGSTORE,
    {
      __topic__: params.topic || 'gotin-website',
      __source__: source[params.data_type] || 'pv-data',
    }
  );
  let data = {
    from_url: document.referrer,      // 前置路由
    url: window.location.href,        // 当前页面url的域名
    path: window.location.pathname,   // 当前url的子路径
    param: window.location.search,    // 当前url的参数
    type: 'default',                  // 类型：默认default
    pixels: `${document.body.clientWidth}x${document.body.scrollHeight}`, // 屏幕尺寸 屏幕宽度x总高度
    ...params.data,
  }
  data = JSON.stringify(data);

  // 平台 platform
  const ua = navigator.userAgent.toLowerCase();
  let platform = ''
  if (ua.indexOf('micromessenger') > -1) platform = 'weixin';
  else if (ua.indexOf('weibo') > -1) platform = 'weibo';
  else if (ua.indexOf('android') > -1 || ua.indexOf('adr') > -1) platform = 'android';
  else if (ua.indexOf('iphone') > -1) platform = 'iphone';
  else platform = 'pc';

  Trackering.push('data', data); // 动态数据信息 类型需要是json字符串
  Trackering.push('data_type', params.data_type || 'pv'); // 上一个页面的跳转url
  Trackering.push('data_id', uuidv4()); // 数据id全局唯一
  Trackering.push('event_id', '');
  Trackering.push('session_id', '');
  Trackering.push('user_id', ''); // 用户id
  Trackering.push('event_time', Day().format('YYYY-MM-DD HH:mm:ss')); // 数据采集时间 前端传的 0时区时间
  Trackering.push('user_type', '2'); // 是否是游客 2游客 1注册用户 临时解决方案
  Trackering.push('platform', platform); // 平台(访问来源)
  Trackering.push('devices', window.navigator.userAgent); // 设备
  Trackering.push('version_num', 'v11'); // 固定字段
  Trackering.logger();
  Trackering.flush()
}

export default tracking