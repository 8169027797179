import tracking from "./track"
import { isBrowser } from "./../src/service/auth";

const pageTrack = (to) => {
  const targetData = {}
  if (to) {
    targetData.target_url = to
  } 
  tracking({data_type:'click', data: targetData})
}

const param2Obj =  (url) => {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}
const isNotIOS = () =>  navigator && navigator.userAgent.toLowerCase().indexOf('iphone') === -1

const jumpWithNewTab = (url) => {
  const jumpUrl = window.open("_black");
  jumpUrl.location.href = url
}
// 生成随机数
const Generate = () => {
  const random = Math.random().toString(36).substr(2) + '_' + new Date().getTime();
  return random
}

const getImageAddress = (path) => `${process.env.GATSBY_OSS_URL}/${path}.png?v=${Generate()}`
export {
  param2Obj,
  pageTrack,
  jumpWithNewTab,
  isNotIOS,
  Generate,
  getImageAddress
}